import React, {useState, useEffect} from 'react';

export function CommuneAutocomplete(props) {

    const [searchInput, setSearchInput] = useState("");
    const [suggestions, setSuggestions] = useState([]);

    const placeholder = props.placeholder;

    useEffect(() => {
        const timeoutRef = setTimeout(() => {
            fetch(`/api/suggestion/commune?q=${searchInput}`)
                .then(res => res.json())
                .then(json => {
                    const apiSuggestions = json.features.map(feature => feature.properties)
                    const sortedApiSuggestions = apiSuggestions.sort((a, b) => {
                        const scoreA = a.importance;
                        const scoreB = b.importance;
                        if (scoreA < scoreB) {
                            return 1;
                        }
                        if (scoreA > scoreB) {
                            return -1;
                        }
                        return 0;
                    })
                    setSuggestions(sortedApiSuggestions);
                })
                .catch(err => {
                    console.error(err);
                    setSuggestions([]);
                });
        }, 300)
        return () => {
            clearTimeout(timeoutRef);
        }
    }, [searchInput]);

    return (
        <form className="maif-form" autoComplete="off">
            <div className="form-group">
                <div className="field-item champ-autocomplete">
                    <input id="field-autocomplete-commune"
                           type="text"
                           autoComplete="off"
                           aria-autocomplete="list"
                           role="combobox"
                           aria-owns="liste-options-field-autocomplete"
                           required="required"
                           placeholder={placeholder}
                           value={searchInput}
                           onChange={(e) => setSearchInput(e.target.value)}/>
                    <ul className="dropdown-menu show"
                        id="liste-options-field-autocomplete"
                        role="listbox"
                        aria-label="Suggestions de saisie">
                        {suggestions.map(suggestion => {
                            return (<li className="dropdown-item"
                                role="option"
                                tabIndex="-1"
                                aria-selected="false"
                                id="field-autocomplete-1"
                                onClick={(e) => {window.location.href = "/communes/" + suggestion.citycode}}>
                                <span>{suggestion.label}</span><span>({suggestion.context})</span>
                            </li>);
                        })}
                    </ul>
                    <div className="sr-only" aria-live="polite" aria-atomic="true">
                        <p>{suggestions.length} résultats disponibles.</p>
                    </div>
                </div>
            </div>
        </form>
    )
}